import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './Timer.scss';

export default function Timer(props: any) {
  const [counter, setCounter] = useState(props.seconds);
  const { t } = useTranslation();

  const tick = () => {
    if (counter === 0) {
      props.refresh();
    } else {
      setCounter(counter - 1);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <div className="timer">
      <span>{t('timer.timeLeftBeforeRefresh')} <b>{counter} {t('timer.seconds')}</b></span>
    </div>
  );
}
