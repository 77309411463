import './App.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Diagnostic from './pages/diagnostic/Diagnostic';
import Home from './pages/home/Home';
import Installation from './pages/installation/Installation';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Help from './pages/help/Help';

library.add(fas);

export default function App() {
  return (
    <Router>
      <div className="app">
        <div className='menu'>
          <a href="/"><img src="logo.png" alt="logo" /></a>
        </div>

        <Routes>
          <Route path="/diagnostic" element={<Diagnostic />}></Route>
          <Route path="/installation" element={<Installation />}></Route>
          <Route path="/help" element={<Help />}></Route>
          <Route path="/" element={<Home />}></Route>
        </Routes>
      </div>

      <ToastContainer/>
    </Router>
  );
}
