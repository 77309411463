import { useTranslation } from 'react-i18next';
import './Help.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';

export default function Help(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='help-div'>
      <h1>{t('help.title')}</h1>
      <span className='help-div-item'><FontAwesomeIcon icon={['fas', 'at']} /> <a href="mailto:hotline@s3pweb.com">hotline@s3pweb.com</a></span>
      <span className='help-div-item'><FontAwesomeIcon icon={['fas', 'phone']} /> <a href="callto:+33255992303"><b>+33 2 55 99 23 03</b></a></span>
      {props.mode === 'modal' ?
        <button onClick={() => props.close()}>{t('help.back')}</button> :
        <button onClick={() => navigate('/')}>{t('help.back')}</button>
      }
    </div>
  );
}