const env = process.env.REACT_APP_ENV;
console.log('🚀 > env:', env);

export default class Constants {
  static API_URL = env === 'production' ? 'https://check-api.s3pweb.io/api/public/v1' : 'https://check-api.s3pweb.xyz/api/public/v1';
  static FILE_API_URL = env === 'production' ? 'https://file-manager-api.s3pweb.io' : 'https://file-manager-api.s3pweb.xyz';
  static S3_PROFILE = env === 'production' ? 'C3' : 'C1';
  static MAX_UPLOAD_SIZE = 8192; // bytes
  static ALLOWED_FILE_TYPES = 'image.*';

  static formRules = {
    registration: {
      minLength: 4,
      maxLength: 10,
      regex: RegExp(/^[\w\d]+$/),
      step: 1,
    },
    type: {
      step: 1,
    },
    brand: {
      step: 1,
    },
    otherBrand: {
      minLength: 2,
      maxLength: 50,
      condition: {
        field: 'brand',
        value: 'other'
      },
      step: 1,
    },
    model: {
      minLength: 2,
      maxLength: 30,
      step: 1,
    },
    boxLocation: {
      minLength: 2,
      maxLength: 100,
      step: 2,
      vehicleType: 'PL'
    },
    tachoType: {
      step: 2,
    },
    installerName: {
      minLength: 2,
      maxLength: 50,
      step: 2,
    },
    installerEmail: {
      minLength: 2,
      regex: RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
      maxLength: 250,
      step: 2,
    },
    companyName: {
      minLength: 2,
      maxLength: 50,
      step: 2,
    },
    fmsCable: {
      step: 2,
      vehicleType: 'PL'
    },
    c5c7: {
      step: 2,
      vehicleType: 'PL'
    },
  };

  static checkFileSize = (size: number): boolean => {
    return (size / Constants.MAX_UPLOAD_SIZE / Constants.MAX_UPLOAD_SIZE) <= Constants.MAX_UPLOAD_SIZE;
  };

  static checkFileType = (type: string): boolean => {
    const match = type.match(Constants.ALLOWED_FILE_TYPES);

    if (match && match.length) {
      return true;
    }

    return false;
  };
}