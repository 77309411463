import { useNavigate } from 'react-router-dom';
import './Home.scss';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../package.json';

export default function Home(props: any) {
  const [displayAlert, setDisplayAlert] = useState(true);
  const [displayGsmLed, setDisplayGsmLed] = useState(false);
  const [displayGpsLed, setDisplayGpsLed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [gsmInfo, setGsmInfo] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [gpsLed, setGpsLed] = useState(0);
  const [gsmLed, setGsmLed] = useState(0);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // Reset installation data
    localStorage.setItem('installationData', '');
  }, []);

  const updateLanguage = (value: string) => {
    i18n.changeLanguage(value);
  };

  const updateVehicleType = (value: string) => {
    setVehicleType(value);
    setDisplayGpsLed(true);
  };

  const checkGpsLed = (value: number) => {
    setGpsLed(value);
    setGsmInfo('');
    setGsmLed(0);

    if (value !== 1 && value !== 3) {
      setDisplayGsmLed(true);
      setErrorMessage('');
    } else {
      setDisplayGsmLed(false);

      const message = value === 1 ? t('home.noGpsSignal') : t('home.boxNotPowered');
      setErrorMessage(message);
    }
  };

  const checkGsmLed = (value: number) => {
    setGsmLed(value);

    if (value !== 4) {
      setDisplayGsmLed(true);
      setErrorMessage('');

      const message = value === 1 ? t('home.normalMode') : value === 2 ? t('home.standbyMode') : t('home.modemInTransmission');
      setGsmInfo(message);
    } else {
      const message = t('home.boxNotPowered');
      setErrorMessage(message);
      setGsmInfo('');
    }
  };

  /**
   * Navigate to the /diagnostic page and save installation data if user click on installation btn
   * @param installation
   */
  const navigateToDiag = (installation: boolean) => {
    if (installation) localStorage.setItem('installationData', JSON.stringify({ vehicleType }));
    navigate('/diagnostic');
  };

  return (
    <div className='home'>
      {displayAlert && <div className='pre-home'>
        <div className='alert-div'>
          <div className='flags'>
            <img onClick={() => updateLanguage('fr')} src="fr.png" alt="Français" />
            <img onClick={() => updateLanguage('en')} src="uk.png" alt="English" />
          </div>

          <span className='info'>{t('home.alertInfo')}</span>


          <h3>{t('home.vehicleType')}</h3>
          <div className='vehicle-type'>
            <button className={vehicleType === 'PL' ? ' active' : ''} onClick={() => updateVehicleType('PL')}>
              {t('home.PL')}
            </button>

            <button className={vehicleType === 'VUL' ? ' active' : ''} onClick={() => updateVehicleType('VUL')}>
              {t('home.VUL')}
            </button>
          </div>

          {displayGpsLed &&
            <div>
              <h3>{t('home.gpsLed')}</h3>
              <div className='led-div'>
                <button className={'led-div-item' + (gpsLed === 1 ? ' active' : '')} onClick={() => checkGpsLed(1)}>
                  <span className='led green'></span>
                </button>

                <button className={'led-div-item' + (gpsLed === 2 ? ' active' : '')} onClick={() => checkGpsLed(2)}>
                  <span className='led green blink-1'></span>
                </button>

                <button className={'led-div-item' + (gpsLed === 3 ? ' active' : '')} onClick={() => checkGpsLed(3)}>
                  <span className='led gray'></span>
                </button>
              </div>
            </div>
          }

          {displayGsmLed && <span className='message'>{t('home.validGpsPosition')}</span>}

          {displayGsmLed &&
            <div>
              <h3>{t('home.gsmLed')}</h3>
              <div className='led-div'>
                <button className={'led-div-item' + (gsmLed === 3 ? ' active' : '')} onClick={() => checkGsmLed(3)}>
                  <span className='led green blink-fast'></span>
                </button>

                <button className={'led-div-item' + (gsmLed === 2 ? ' active' : '')} onClick={() => checkGsmLed(2)}>
                  <span className='led green blink-2'></span>
                </button>

                <button className={'led-div-item' + (gsmLed === 1 ? ' active' : '')} onClick={() => checkGsmLed(1)}>
                  <span className='led green blink-1'></span>
                </button>

                <button className={'led-div-item' + (gsmLed === 4 ? ' active' : '')} onClick={() => checkGsmLed(4)}>
                  <span className='led gray'></span>
                </button>
              </div>
            </div>
          }

          {errorMessage && <span className='error-message'>{errorMessage}</span>}
          {gsmInfo && <span className='message'>{gsmInfo}</span>}

          <div className='alert-buttons'>
            {gsmInfo && <button className='yellow' onClick={() => setDisplayAlert(false)}>
              <FontAwesomeIcon icon={['fas', 'check']} /> Valider
            </button>}
            <button className='light-gray' onClick={() => navigate('/help')}>
              <FontAwesomeIcon icon={['fas', 'headset']} /> Support
            </button>
            <div className='alert-buttons-documentation'>
              <FontAwesomeIcon icon={['fas', 'link']} />
              <a href="https://releasenotes.s3pweb.ovh/DOC_DE_MONTAGE_B-UNIC.pdf" target='blank'>Documentation</a>
            </div>
          </div>

          <span className='app-version'>Version {packageJson.version}</span>
        </div>
      </div >}

      {!displayAlert && <div className='actions'>
        <h2>{t('home.title')}</h2>

        <button onClick={() => navigateToDiag(false)}>{t('home.diagnosis')}</button>
        {/* Same start as diagnosis but with a parameter to redirect to installation after checking state */}
        <button onClick={() => navigateToDiag(true)}>{t('home.declareInstallation')}</button>
      </div>}
    </div >
  );
};