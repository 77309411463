import './Diagnostic.scss';
import Scanner from '../../components/scanner/Scanner';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getInputState } from '../../services/inputs.service';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Timer from '../../components/timer/Timer';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import i18n from '../../i18n';
import Help from '../../pages/help/Help';
import { getTachocheckCommand, postTachocheckCommand } from '../../services/commands.service';

export default function Diagnostic() {
  const [scanValue, setScanValue] = useState('');
  const [registrationValue, setRegistrationValue] = useState('');
  const [registrationError, setRegistrationError] = useState('');
  const [displayStep1, setDisplayStep1] = useState(false);
  const [displayStep2, setDisplayStep2] = useState(false);
  const [isInstallation, setIsInstallation] = useState(false);
  const [displayInstallationBtn, setDisplayInstallationBtn] = useState(false);
  const [displaySupportBtn, setDisplaySupportBtn] = useState(false);
  const [displayNoKlineInfo, setDisplayNoKlineInfo] = useState(false);
  const [displayHelp, setDisplayHelp] = useState(false);
  const [displayTimer, setDisplayTimer] = useState(false);
  const [inputData, setInputData] = useState({} as { [key: string]: any; });
  const [loading, setLoading] = useState(false);
  const [displaySendNewCommandBtn, setDisplaySendNewCommandBtn] = useState(false);
  const [rtdWaiting, setRtdWaiting] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // Get installation data from local storage
    const data = localStorage.getItem('installationData');
    const json = data ? JSON.parse(data) : null;

    if (json?.vehicleType) {
      setIsInstallation(true);
    }

    // Display the first step after local storage loading
    setDisplayStep1(true);
  }, []);

  const onNewScanResult = (data: string) => {
    setScanValue(data);
  };

  const handleScanValue = (e: any) => {
    e.preventDefault();

    const value = e.target?.value;
    setScanValue(value);
  };

  const handleRegistration = (e: any) => {
    e.preventDefault();

    const value = e.target.value;

    checkRegistration(value);
    setRegistrationValue(value);
  };

  /**
   * Check vehicle registration (without spaces or dashes)
   * @param value
   */
  const checkRegistration = (value: string) => {
    const regex = RegExp(/^[\w\d]+$/);

    if (value && !regex.test(value)) {
      setRegistrationError(t('diagnosis.registrationError') as string);
    } else {
      setRegistrationError('');
    }
  };

  /**
   * Submit data to get state
   * @param e
   */
  const submit = async (e: any) => {
    if (e) {
      e.preventDefault();
    }

    setDisplayTimer(false);

    if (!scanValue && !registrationValue) {
      if (isInstallation) {
        toast.error(t('diagnosis.noValueErrorInInstallation'));
      } else {
        toast.error(t('diagnosis.noValueError'));
      }
      return;
    }

    let queryType = 'scan';

    // Check registration if no scan value
    if (!scanValue && registrationValue) {
      checkRegistration(registrationValue);
      queryType = 'registration';
    }

    setLoading(true);

    const result = await getInputState(queryType === 'scan' ? scanValue : registrationValue, queryType);

    // If success, show step 2 with state and hide support btn
    if (result?.success && result?.data) {
      // * Display that there is no kLine if the state is empty or if the key is missing
      if (!result.data?.kLine) {
        setDisplayNoKlineInfo(true);
      } else {
        setDisplayNoKlineInfo(false); // Hide fake kLine info if received in the state after a refresh
      }

      // Load RTD commands and set input data
      getCommands(result.data);

      setDisplayStep1(false);
      setDisplayStep2(true);
      setDisplaySupportBtn(false);

      // * Display button only if it's an installation, if state has data and if it's an imei or sn
      if (isInstallation && scanValue && Object.keys(result.data).length) {
        setDisplayInstallationBtn(true);
      }
    } else {
      // Else, display toast with error and support btn if needed
      if (result?.error === 400) {
        toast.error(t('diagnosis.error400'));
        setDisplaySupportBtn(true);
      } else if (result?.error === 404) {
        toast.error(queryType === 'registration' ? t('diagnosis.error404Registration') : t('diagnosis.error404Imei'));
      } else if (result?.error === 422) {
        toast.error(queryType === 'registration' ?
          t('diagnosis.error422', { value: t('registration') }) : t('diagnosis.error422', { value: t('imeiOrSerial') }));
        setDisplaySupportBtn(true);
      } else {
        toast.error(t('diagnosis.error'));
      }

      setDisplayInstallationBtn(false);
    }

    // Display timer to block refresh by user
    setDisplayTimer(true);
    setLoading(false);
  };

  const back = () => {
    setDisplayInstallationBtn(false);
    setDisplayStep2(false);
    setDisplayStep1(true);
  };

  const goToInstallation = () => {
    // Get installation data from local storage
    const data = localStorage.getItem('installationData');
    const json = data ? JSON.parse(data) : null;

    if (json?.vehicleType) {
      // Add scan value & diagnostic state to installation data
      json.scanValue = scanValue;
      json.diagnostic = inputData;

      localStorage.setItem('installationData', JSON.stringify(json));

      navigate('/installation');
    } else {
      console.error('No installation data.');
    }
  };

  const formatDate = (date: number, capitalize: boolean) => {
    if (i18n.language === 'en') {
      return `${capitalize ? 'The' : 'the'} ${dayjs(date).format('MM/DD/YYYY')} at ${dayjs(date).format('HH:mm:ss')}`;
    } else {
      return `${capitalize ? 'Le' : 'le'} ${dayjs(date).format('DD/MM/YYYY')} à ${dayjs(date).format('HH:mm:ss')}`;
    }
  };

  const formatState = (key: string) => {
    const state = inputData[key];

    if (state) {
      switch (key) {
        case 'coordinates':
          return { title: 'GPS', text: t('diagnosis.lastPosition'), date: formatDate(state.ts, false), };
        case 'currentActivity':
          return { title: t('diagnosis.activity'), text: `${state.value}`, boldText: true, date: formatDate(state.ts, false), };
        case 'mileage':
          return { title: t('diagnosis.mileage'), text: `${state.value} km`, boldText: true, date: formatDate(state.ts, false), };
        case 'totalFuel':
          return { title: t('diagnosis.totalFuel'), text: `${state.value} l.`, boldText: true, date: formatDate(state.ts, false), };
        case 'fuelLevel':
          return { title: t('diagnosis.fuelLevel'), text: `${state.value} %`, boldText: true, date: formatDate(state.ts, false), };
        case 'engineSpeed':
          return { title: t('diagnosis.engineSpeed'), text: `${state.value} ${t('diagnosis.rpm')}`, boldText: true, date: formatDate(state.ts, false), };
        case 'lastFileUpload':
          return { title: t('diagnosis.lastFileUpload'), text: '', date: formatDate(state.ts, true), };
        case 'kLine':
          return { title: t('diagnosis.kLine'), text: '', icon: state.value === 1 ? 'check' : 'xmark' };
        case 'ignition':
          return { title: t('diagnosis.ignition'), text: '', icon: state.value === 'ON' ? 'check' : 'xmark' };
        case 'rtd':
          return { title: t('diagnosis.rtd'), text: '', icon: rtdWaiting ? 'hourglass-half' : state?.result ? 'check' : 'xmark' };
      }
    }

    return { title: '', text: '', date: '', icon: '' };
  };

  /**
   * Try to get rtd commands for this input
   * @param state
   */
  const getCommands = async (state: any) => {
    const result = await getTachocheckCommand(scanValue);

    if (result?.success && result?.data) {
      // Update state with RTD result (value + ts, visible in Jira task)
      const _tempData = state;
      _tempData.rtd = result.data;
      setInputData(_tempData);

      // Hide waiting icon and display the send new command button
      setRtdWaiting(false);
      setDisplaySendNewCommandBtn(true);
    } else {
      // Display waiting icon if error, and send a new command
      setRtdWaiting(true);

      // Update state with fake RTD value (empty object)
      const _tempData = state;
      _tempData.rtd = {};
      setInputData(_tempData);

      await postTachocheckCommand(scanValue);
    }
  };

  const sendNewCommand = async () => {
    // Hide send new command button and display waiting icon
    setDisplaySendNewCommandBtn(false);
    setRtdWaiting(true);
    await postTachocheckCommand(scanValue);
  };

  return (
    <div className='diagnostic'>
      <div className='title-div'>
        <h2>{t('diagnosis.title')}</h2>
      </div>

      {/* STEP 1 */}
      {displayStep1 && !displayHelp ? <div className='step-1'>
        <div className='scanner-div'>
          <Scanner
            fps={10}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={onNewScanResult} />
        </div>

        <div className='scan-data'>
          <label>{t('diagnosis.scanInfo')}</label>
          <input minLength={6} maxLength={16} type="text" onChange={(e) => handleScanValue(e)} value={scanValue} placeholder='...'></input>
        </div>

        {!isInstallation ? <div className='registration-data'>
          <label>{t('diagnosis.registrationInfo')}</label>
          <input minLength={4} maxLength={10} type="text" onChange={(e) => handleRegistration(e)} placeholder='...'></input>
          {registrationError && <span className='error-message'>{registrationError}</span>}
        </div> : ''}

        <button className='submit-btn' onClick={submit} disabled={!!registrationError}>{t('diagnosis.validate')}</button>
        {displaySupportBtn ? <button className='support-btn' onClick={() => setDisplayHelp(true)}>
          <FontAwesomeIcon icon={['fas', 'headset']} /> Support
        </button> : ''}
        <button className='back-btn' onClick={() => navigate('/')}>{t('diagnosis.back')}</button>
      </div> : ''}

      {/* STEP 2 */}
      {displayStep2 ? <div className='step-2'>
        {loading && <div className='loading'>
          <div className="lds-dual-ring"></div>
        </div>}

        {/* Format state for every key */}
        {Object.keys(inputData).length && !loading ? <div className='input-data'>
          {Object.keys(inputData).map((key, index) => {
            const formattedState = formatState(key);

            if (formattedState && Object.keys(formattedState)) {
              return (
                // Display as column if no icon, else display as row
                <div key={index} className={'input-data-item' + (formattedState.icon ? ' row' : '')}>
                  <label>{formattedState.title}</label>

                  {/* Text & date */}
                  {formattedState.text || formattedState.date ?
                    <span>{formattedState.boldText ? <b>{formattedState.text}</b> : formattedState.text} {formattedState.date}</span>
                    : ''}

                  {/* Icon */}
                  {formattedState.icon ?
                    <span className={formattedState.icon === 'check' ? 'green-icon' : formattedState.icon === 'hourglass-half' ? 'orange-icon' : 'red-icon'}>
                      <FontAwesomeIcon icon={['fas', formattedState.icon as any]} />
                    </span>
                    : ''}
                </div>
              );
            }

            return '';
          })}

          {/* Display no kLine info if the key is missing in state */}
          {displayNoKlineInfo ?
            <div className='input-data-item row'>
              <label>{t('diagnosis.kLine')}</label>

              <span className='check red-icon'>
                <FontAwesomeIcon icon={['fas', 'xmark']} />
              </span>
            </div>
            : ''}
        </div> : ''}

        {/* No input data */}
        {!Object.keys(inputData).length && !loading ? <div className='no-input-data'>
          {/* Display no kLine info if the state is empty */}
          {displayNoKlineInfo ?
            <div className='no-kline-info'>
              <label>{t('diagnosis.kLine')}</label>

              <span className='no-kline-info-icon'>
                <FontAwesomeIcon icon={['fas', 'xmark']} />
              </span>
            </div>
            : ''}

          <span>{t('diagnosis.noData')}</span>
        </div> : ''}


        {!loading && <div className='refresh-div'>
          {displayTimer && <Timer refresh={submit} seconds={15} />}

          {displayInstallationBtn ?
            <button
              className='next-btn'
              onClick={goToInstallation}>
              {t('diagnosis.next')} <FontAwesomeIcon icon={['fas', 'arrow-right']} />
            </button> : ''}
          {displaySendNewCommandBtn ?
            <button className='action-btn' onClick={sendNewCommand}>
              {t('diagnosis.sendNewCommand')} <FontAwesomeIcon icon={['fas', 'shuffle']} />
            </button> : ''}
          <button className='back-btn' onClick={back}>{t('diagnosis.back')}</button>
        </div>}
      </div> : ''}

      {displayHelp ? <Help mode="modal" close={() => setDisplayHelp(false)} /> : ''}
    </div>
  );
}