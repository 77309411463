import Constants from "../utils/constants.utils";

export async function getInputState(value: string, type: string) {
  let url = '';

  if (type === 'registration') {
    url = `${Constants.API_URL}/devices/state-by-reg?registration=${value.trim()}`;
  } else {
    url = `${Constants.API_URL}/devices/state-by-imei-or-sn?value=${value.trim()}`;
  }

  try {
    const req = await fetch(url, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
      method: 'GET',
    });

    if (req.status === 200) {
      return { success: true, data: await req.json() };
    } else {
      return { success: false, error: req.status };
    }
  } catch (error) {
    console.error('🚀 > getInputState > error:', error);
  }
};;
