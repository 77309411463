import { Html5QrcodeScanner } from 'html5-qrcode';
import './Scanner.scss';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function Scanner(props: any) {
  const { t } = useTranslation();
  let html5QrcodeScanner: any;

  useEffect(() => {
    const config = {
      fps: props.fps,
      qrbox: props.qrbox,
      disableFlip: props.disableFlip,
    };

    // TODO voir si possible à résoudre
    // eslint-disable-next-line react-hooks/exhaustive-deps
    html5QrcodeScanner = new Html5QrcodeScanner('qrcode-scanner', config, true);
    html5QrcodeScanner.render(onScanSuccess, onScanError);

    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().catch((error: any) => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
  }, []);

  const onScanSuccess = (decodedText: string, decodedResult: any) => {
    // ! DEBUG
    // console.log('🚀 > onNewScanResult > decodedResult:', decodedResult);
    // console.log('🚀 > onNewScanResult > decodedText:', decodedText);

    if (decodedText?.length) {
      html5QrcodeScanner.pause();
      toast.success(t('diagnosis.scanSuccess'));
      props.qrCodeSuccessCallback(decodedText);
    }
  };

  const onScanError = (err: any) => {
    // ! DEBUG
    // console.log('🚀 > onScanError > err:', err);
  };

  return (
    <div className="scanner">
      <div id={'qrcode-scanner'} />
    </div>
  );
}