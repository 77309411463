import Constants from "../utils/constants.utils";

export async function postTachocheckCommand(imei: string) {
  const url = `${Constants.API_URL}/commands/tachocheck?imei=${imei}`;

  try {
    const req = await fetch(url, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
      method: 'POST',
    });

    if (req.status === 200) {
      return { success: true, data: await req.json() };
    } else {
      return { success: false, error: req.status };
    }
  } catch (error) {
    console.log('🚀 > postTachocheckCommand > error:', error)
  }
};

export async function getTachocheckCommand(imei: string) {
  const url = `${Constants.API_URL}/commands/tachocheck?imei=${imei}`;

  try {
    const req = await fetch(url, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
      method: 'GET',
    });

    if (req.status === 200) {
      return { success: true, data: await req.json() };
    } else {
      return { success: false, error: req.status };
    }
  } catch (error) {
    console.log('🚀 > getTachocheckCommand > error:', error)
  }
};
