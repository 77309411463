import { useEffect, useState } from 'react';
import './Installation.scss';
import Constants from '../../utils/constants.utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { uploadFile } from '../../services/files.service';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createJiraTask } from '../../services/jira.service';
import Swal from 'sweetalert2';

export default function Installation() {
  const [formData, setFormData] = useState({
    registration: '',
    vehicleName: '',
    comments: '',
    brand: '',
    otherBrand: '',
    type: '',
    model: '',
    boxLocation: '',
    installerName: '',
    installerEmail: '',
    companyName: '',
    fmsCable: '',
    tachoType: '',
    c5c7: '',
  });
  const [type, setType] = useState('');
  const [fmsCable, setFmsCable] = useState('');
  const [c5c7, setC5c7] = useState('');
  const [scanValue, setScanValue] = useState('');
  const [diagnosticResult, setDiagnosticResult] = useState({});
  const [errors, setErrors] = useState({} as any);
  const [showStep1, setShowStep1] = useState(true);
  const [showStep2, setShowStep2] = useState(false);
  const [images, setImages] = useState([] as any);
  const [imageLoading, setImageLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Get installation data from local storage
    const data = localStorage.getItem('installationData');
    const json = data ? JSON.parse(data) : null;

    // If data, get it, else redirect user to homepage
    if (json?.scanValue && json?.vehicleType && json?.diagnostic) {
      setScanValue(json.scanValue);
      setDiagnosticResult(json.diagnostic);

      // * Detect data alteration from local storage
      if (json.vehicleType === 'PL' || json.vehicleType === 'VUL') {
        setType(json.vehicleType);
        setFormData({ ...formData, type: json.vehicleType });
      } else {
        console.error('Local storage alteration detected, back to home.');
        navigate('/');
      }
    } else {
      navigate('/');
    }
    // TODO voir si possible à résoudre
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  /**
   * Handle radio buttons outside of the handleChange method to make it works
   * @param event
   */
  const handleFmsCable = (event: any, step: number) => {
    setFmsCable(event.target.value);
    checkRules('fmsCable', event.target.value, false, step);
    setFormData({ ...formData, fmsCable: event.target.value });
  };

  /**
   * Handle radio buttons outside of the handleChange method to make it works
   * @param event
   */
  const handleC5c7 = (event: any, step: number) => {
    setC5c7(event.target.value);
    checkRules('c5c7', event.target.value, false, step);
    setFormData({ ...formData, c5c7: event.target.value });
  };

  /**
   * Handle input changes
   * @param event
   */
  const handleChange = (event: any, step: number) => {
    event.preventDefault();

    const { name, value } = event.target;

    // Check if value is correct
    checkRules(name, value, false, step);
    setFormData({ ...formData, [name]: value });
  };

  /**
   * Check fields with rules
   * @param name
   * @param value
   * @param returnError
   * @param step 0 => all, 1 for step 1 and 2 for step 2
   * @returns
   */
  const checkRules = (name: string, value: any, returnError: boolean, step: number) => {
    const rules: any = Constants.formRules;

    let error = '';
    let needToCheckValue = true;

    if (rules[name]) {
      // Check if this field has conditions before checking other rules
      if (rules[name].condition) {
        const _formData: any = { ...formData };
        const conditionField = rules[name].condition.field;
        const conditionValue = rules[name].condition.value;

        if (_formData[conditionField] !== conditionValue) {
          needToCheckValue = false;
        }
      }

      // Check if this field is required for this vehicle type
      if (rules[name].vehicleType && type !== rules[name].vehicleType) {
        needToCheckValue = false;
      }

      if (
        needToCheckValue &&
        (step === 0 || (rules[name]?.step === step)) &&
        rules[name].minLength &&
        value?.length < rules[name].minLength
      ) {
        error = t('installation.minLengthError', { length: rules[name].minLength });
      }

      if (
        needToCheckValue &&
        (step === 0 || (rules[name]?.step === step)) &&
        rules[name].minLength &&
        value?.length > rules[name].maxLength
      ) {
        error = t('installation.maxLengthError', { length: rules[name].maxLength });
      }

      if (
        needToCheckValue &&
        (step === 0 || (rules[name]?.step === step)) &&
        rules[name].regex && value
      ) {
        const regex = rules[name].regex;

        if (!regex.test(value)) {
          error = t('installation.invalidValue');
        }
      }

      if (
        needToCheckValue &&
        (step === 0 || (rules[name]?.step === step)) &&
        !value
      ) {
        error = t('installation.requiredValue');
      }
    }

    // Update errors object
    if (!returnError) {
      setErrors({ ...errors, [name]: error });
    } else {
      return { name, error };
    }
  };

  /**
   * Submit form
   * @param event
   * @returns
   */
  const submit = async (event: any) => {
    event.preventDefault();

    setSubmitted(true);

    // Check form data
    if (Object.keys(formData).length) {
      const data: any = { ...formData };
      const errors: any = {};

      for (const key of Object.keys(data)) {
        const checkData = checkRules(key, data[key], true, 0);

        if (checkData?.name && checkData?.error) {
          errors[checkData.name] = checkData.error;
        }
      }

      setErrors(errors);

      // Check errors and return if there is one or more
      if (Object.keys(errors).length) {
        for (const key of Object.keys(errors)) {
          if (errors[key].length) {
            setSubmitted(false);
            return;
          }
        }
      }
    }

    // * Clear otherBrand if brand is not other (user can set otherBrand and update brand to another one)
    if (formData.brand !== 'other' && formData.otherBrand) {
      setFormData({ ...formData, otherBrand: "" });
    }

    const obj = JSON.parse(JSON.stringify(formData));

    // Add scan value
    obj.scanValue = scanValue;

    // Add diagnostic result
    obj.diagnostic = diagnosticResult;

    // Erase brand by otherBrand if data and delete otherBrand
    if (obj.brand && obj.otherBrand) {
      obj.brand = obj.otherBrand;
      delete obj.otherBrand;
    }

    // Delete optionnal keys if no value
    if (!obj.vehicleName) delete obj.vehicleName;
    if (!obj.otherBrand) delete obj.otherBrand;
    if (!obj.comments) delete obj.comments;

    // Convert fmsCable value to boolean
    obj.fmsCable = obj.fmsCable === 'yes' ? true : false;

    // Convert c5c7 value to boolean
    obj.c5c7 = obj.c5c7 === 'yes' ? true : false;

    // Send 'NA' in boxLocation for VUL
    if (obj.type === 'VUL') {
      obj.boxLocation = 'NA';
    }

    // Check images and add it to obj medias
    if (images?.length) {
      obj.medias = [];

      for (const item of images) {
        // * Take only path + extension
        if (item.url && item.url.includes('.com/')) {
          const split = item.url.split('.com/');

          if (split[1]) {
            obj.medias.push(split[1]);
          }
        }
      }
    } else {
      toast.warning(t('installation.noImage'));
      setSubmitted(false);
      return;
    }

    const createResult = await createJiraTask(obj);

    // If success, display toast and redirect to home
    if (createResult?.success) {
      Swal.fire({
        title: String(t('installation.success')),
        text: '',
        icon: 'success',
        confirmButtonText: 'OK'
      });

      // Reset installation data
      localStorage.setItem('installationData', '');

      navigate('/');
    } else {
      // Else, adapt error message
      if (createResult?.error === 500) {
        toast.error(t('installation.error500'));
      } else {
        toast.error(t('installation.error'));
      }
    }

    setSubmitted(false);
  };

  const next = (event: any) => {
    event.preventDefault();

    setSubmitted(true);

    console.log('🚀 > next > formData:', formData);

    // Check form data
    if (Object.keys(formData).length) {
      const data: any = { ...formData };
      const errors: any = {};

      for (const key of Object.keys(data)) {
        const checkData = checkRules(key, data[key], true, 1);

        if (checkData?.name && checkData?.error) {
          errors[checkData.name] = checkData.error;
        }
      }

      setErrors(errors);

      // Check errors and return if there is one or more
      if (Object.keys(errors).length) {
        for (const key of Object.keys(errors)) {
          if (errors[key].length) {
            return;
          }
        }
      }
    }

    setSubmitted(false);
    setShowStep1(false);
    setShowStep2(true);
  };

  const back = (event: any) => {
    event.preventDefault();
    setShowStep1(true);
    setShowStep2(false);
  };

  const backToDiagnostic = () => {
    navigate('/diagnostic?installation=true');
  };

  /**
   * Handle files that user wants to upload
   * @param files input
   * @returns
   */
  const handleFileInputChange = (files: FileList) => {
    // Only 1 file at a time
    if (files.length === 1) {
      const imageFile = files[0];

      // ! DEBUG
      // console.log('🚀 > handleFileInputChange > imageFile:', imageFile);

      // Check image size
      if (!Constants.checkFileSize(imageFile.size)) {
        console.error(`Maximum file size exceeded. Max file size is: ${Constants.MAX_UPLOAD_SIZE}`);
        toast.warning(t('installation.invalidFileSize'));
        return false;
      } else if (!Constants.checkFileType(imageFile.type)) { // check if the user isn't trying to upload anything else then an image
        console.error('File type is not allowed.');
        toast.warning(t('installation.invalidFileType'));
        return false;
      }

      uploadImage(imageFile);
    } else {
      console.error(files.length === 0 ? 'NO IMAGE UPLOADED' : 'YOU CAN ONLY UPLOAD ONE IMAGE AT THE TIME');
      toast.warning(t(files.length === 0 ? 'noImageUploaded' : 'uploadLimit'));
      return false;
    }
  };

  /**
   * Call the input file element
   */
  const addPhotos = () => {
    const inputElement = document.getElementById('input-files');
    inputElement?.click();
  };

  /**
   * Upload image on S3
   * @param file
   */
  const uploadImage = async (file: any) => {
    setImageLoading(true);

    let uploadResult = null;

    try {
      uploadResult = await uploadFile(file);
    } catch (error) {
      console.error('🚀 > uploadImage > error:', error);
    }

    if (uploadResult) {
      // ! DEBUG
      // console.log('🚀 > uploadImage > uploadResult:', uploadResult);

      if (uploadResult.success && uploadResult.url && uploadResult.thumb) {
        setImages([...images, { url: uploadResult.url, thumbnail: uploadResult.thumb }]);
      }
    } else {
      toast.error(t('installation.uploadError'));
    };

    setImageLoading(false);
  };

  const removeImage = (item: any) => {
    setImages(images.filter((i: any) => i.url !== item.url));
  };

  return (
    <div className='installation'>
      <h2>{t('installation.title')}</h2>

      {/* STEP 1 */}
      {showStep1 && <div className='step'>
        {/* Registration */}
        <div className='form-item'>
          <label>{t('installation.vehicleRegistration')}*</label>
          <input
            required
            minLength={4}
            maxLength={10}
            name="registration"
            value={formData?.registration || ''}
            type="text"
            onChange={(e) => handleChange(e, 1)}
            placeholder='...'
            className={errors?.registration?.length ? 'input-error' : ''}
          ></input>
          {errors?.registration?.length ? <span className='error-message'>{errors.registration}</span> : ''}
        </div>

        {/* Code */}
        <div className='form-item'>
          <label>{t('installation.vehicleName')}</label>
          <input
            maxLength={30}
            type="text"
            name="vehicleName"
            value={formData?.vehicleName || ''}
            onChange={(e) => handleChange(e, 1)}
            placeholder='...'
          ></input>
        </div>

        {/* Brand */}
        <div className='form-item'>
          <label>{t('installation.brand')}*</label>
          <select
            required
            name="brand"
            onChange={(e) => handleChange(e, 1)}
            value={formData.brand}
            className={errors?.brand?.length ? 'input-error' : ''}
          >
            <option disabled value="">{t('installation.chooseBrand')}</option>
            <option value="iveco">Iveco</option>
            <option value="volvo">Volvo</option>
            <option value="renault">Renault</option>
            <option value="mercedes">Mercedes</option>
            <option value="man">Man</option>
            <option value="daf">Daf</option>
            <option value="scania">Scania</option>
            <option value="other">{t('installation.other')}</option>
          </select>
          {errors?.brand?.length ? <span className='error-message'>{errors.brand}</span> : ''}
        </div>

        {/* Other (if selected before) */}
        {formData?.brand === 'other' && <div className='form-item'>
          <label>{t('installation.otherBrand')}*</label>
          <input
            required
            minLength={2}
            maxLength={50}
            type="text"
            name="otherBrand"
            onChange={(e) => handleChange(e, 1)}
            placeholder='...'
            className={errors?.otherBrand?.length ? 'input-error' : ''}
          ></input>
          {errors?.otherBrand?.length ? <span className='error-message'>{errors.otherBrand}</span> : ''}
        </div>}

        {/* Model */}
        <div className='form-item'>
          <label>{t('installation.model')}*</label>
          <input
            required
            minLength={2}
            maxLength={30}
            type="text"
            name="model"
            value={formData?.model || ''}
            onChange={(e) => handleChange(e, 1)}
            placeholder='...'
            className={errors?.model?.length ? 'input-error' : ''}
          ></input>
          {errors?.model?.length ? <span className='error-message'>{errors.model}</span> : ''}
        </div>

        <button className='submit-btn' onClick={next}>{t('installation.next')} <FontAwesomeIcon icon={['fas', 'arrow-right']} /></button>
        <button className='back-btn' onClick={backToDiagnostic}>{t('installation.back')}</button>
      </div>}

      {/* STEP 2 */}
      {showStep2 && <div className='step'>
        {/* Box location (Only for PL) */}
        {type === 'PL' ?
          <div className='form-item'>
            <label>{t('installation.boxLocation')}</label>
            <input
              required
              minLength={2}
              maxLength={100}
              name="boxLocation"
              type="text"
              value={formData?.boxLocation || ''}
              onChange={(e) => handleChange(e, 2)}
              placeholder='...'
              className={errors?.boxLocation?.length ? 'input-error' : ''}
            ></input>
            {errors?.boxLocation?.length ? <span className='error-message'>{errors.boxLocation}</span> : ''}
          </div> : ''}

        {/* Tacho type */}
        <div className='form-item'>
          <label>{t('installation.tachoType')}*</label>
          <select
            required
            name="tachoType"
            onChange={(e) => handleChange(e, 2)}
            value={formData.tachoType}
            className={errors?.tachoType?.length ? 'input-error' : ''}
          >
            <option disabled value="">{t('installation.chooseType')}</option>
            <option value="Stoneridge SE 500">Stoneridge SE 500</option>
            <option value="VDO 1381">VDO 1381</option>
            <option value="INTELLIC EFAS">INTELLIC EFAS</option>
            <option value="other">{t('installation.other')}</option>
          </select>
          {errors?.tachoType?.length ? <span className='error-message'>{errors.tachoType}</span> : ''}
        </div>


        {/* Installer name */}
        <div className='form-item'>
          <label>{t('installation.installerName')}*</label>
          <input
            required
            minLength={2}
            maxLength={50}
            name="installerName"
            type="text"
            value={formData?.installerName || ''}
            onChange={(e) => handleChange(e, 2)}
            placeholder='...'
            className={errors?.installerName?.length ? 'input-error' : ''}
          ></input>
          {errors?.installerName?.length ? <span className='error-message'>{errors.installerName}</span> : ''}
        </div>


        {/* Installer email */}
        <div className='form-item'>
          <label>{t('installation.installerEmail')}*</label>
          <input
            required minLength={2}
            maxLength={250}
            name="installerEmail"
            type="email" value={formData?.installerEmail || ''}
            onChange={(e) => handleChange(e, 2)}
            placeholder='...'
            className={errors?.installerEmail?.length ? 'input-error' : ''}
          ></input>
          {errors?.installerEmail?.length ? <span className='error-message'>{errors.installerEmail}</span> : ''}
        </div>

        {/* Company */}
        <div className='form-item'>
          <label>{t('installation.companyName')}*</label>
          <input
            required
            minLength={2}
            maxLength={50}
            name="companyName"
            type="text"
            value={formData?.companyName || ''}
            onChange={(e) => handleChange(e, 2)}
            placeholder='...'
            className={errors?.companyName?.length ? 'input-error' : ''}
          ></input>
          {errors?.companyName?.length ? <span className='error-message'>{errors.companyName}</span> : ''}
        </div>

        {/* FMS Cable (Only for PL) */}
        {type === 'PL' ?
          <div className='form-item'>
            <span>{t('installation.fmsCable')}*</span>
            <div className='form-item-row'>
              <input type="radio" name="fmsCable" value="yes" id="yes" checked={fmsCable === 'yes'} onChange={(e) => handleFmsCable(e, 2)} />
              <label htmlFor="yes">{t('installation.yes')}</label>

              <input type="radio" name="fmsCable" value="no" id="no" checked={fmsCable === 'no'} onChange={(e) => handleFmsCable(e, 2)} />
              <label htmlFor="no">{t('installation.no')}</label>
            </div>
            {errors?.fmsCable?.length ? <span className='error-message'>{errors.fmsCable}</span> : ''}
          </div> : ''}

        {/* Tachy question (Only for PL) */}
        {type === 'PL' ?
          <div className='form-item'>
            <span>{t('installation.c5c7Question')}*</span>
            <div className='form-item-row'>
              <input type="radio" name="c5c7" value="yes" id="yes" checked={c5c7 === 'yes'} onChange={(e) => handleC5c7(e, 2)} />
              <label htmlFor="yes">{t('installation.yes')}</label>

              <input type="radio" name="c5c7" value="no" id="no" checked={c5c7 === 'no'} onChange={(e) => handleC5c7(e, 2)} />
              <label htmlFor="no">{t('installation.no')}</label>
            </div>
            {errors?.c5c7?.length ? <span className='error-message'>{errors.c5c7}</span> : ''}
          </div> : ''}

        {/* Comments */}
        <div className='form-item'>
          <label>{t('installation.comments')}</label>
          <textarea
            maxLength={120}
            name="comments"
            value={formData?.comments || ''}
            onChange={(e) => handleChange(e, 2)}
          ></textarea>
        </div>

        {/* Images */}
        <div className='form-item'>
          <label>Images*</label>

          <button className="add-image-btn" onClick={() => addPhotos()} disabled={imageLoading}>
            {!imageLoading ?
              <span>
                <FontAwesomeIcon icon={['fas', 'image']} /> {t('installation.addImage') + (images.length ? ` (${images.length})` : '')}
              </span>
              :
              <FontAwesomeIcon className="fa-spin" icon={['fas', 'circle-notch']} />
            }
          </button>

          <input type="file" name="files[]" id="input-files" accept="image/*" className="image-upload"
            onChange={($event: any) => handleFileInputChange($event.target.files)} hidden />

          {images && images.length ? <div className="img-list">
            {images.map((item: any, index: number) => {
              return <div key={index} className="img-list-item">
                <img alt='' src={item.thumbnail}></img>
                <button onClick={() => removeImage(item)}><FontAwesomeIcon icon={['fas', 'close']} /></button>
              </div>;
            })}
          </div> : ''}
        </div>

        <button className='submit-btn' onClick={submit} disabled={submitted}>{t('installation.validate')}</button>
        <button className='back-btn' onClick={back}>{t('installation.back')}</button>
      </div>}
    </div>
  );
};