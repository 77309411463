import Constants from '../utils/constants.utils';

export async function createJiraTask(body: any) {
  try {
    const req = await fetch(`${Constants.API_URL}/devices/installation`, {
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(body)
    });

    if (req.status === 201) {
      return { success: true };
    } else {
      return { success: false, error: req.status };
    }
  } catch (error) {
    console.error('🚀 > createJiraTask > error:', error);
  }
}